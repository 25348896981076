body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 1024px) {

  .sc-bZQynM{
      left:calc(100% - 200px) !important;
      position:absolute;
  }
  .bryBoI{
      display:none !important;
  }

  .dPaGNm{
      max-width:100% !important;
      height:auto;
      overflow-y:scroll;
      scrollbar-color: rgb(0, 0, 0) rgb(0, 0, 0);
  }

 .dPaGNm > .iOkoWO{
      width: 27%;
      padding-right: 3%;
      -webkit-flex: unset;
              flex: unset;
      margin-bottom:10px;
  }

  .dPaGNm > div:nth-of-type(2){
    display:block;
    -webkit-flex: unset;
            flex: unset;
    width: calc(100% - 62%);
  }

}
